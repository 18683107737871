import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import appLogoDesktop from "../assets/betternow-logo-alt.svg";
import betternowUser from "../assets/betternow-user.png";
import urgentHelp from "../assets/urgent-help.svg";
import panicHelp from "../assets/panic-help.png"; // Importing panic-help image

import "./StaticPage.css";

const StaticPage: React.FC<{ title: string; iframeURL: string }> = ({
  title,
  iframeURL,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isInApp = params.get("in_app") === "true";
  const isLandingPage = params.get("landing_page") === "true";
  const path = location.pathname; // Get the current path

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Conditionally add '?in_app=true' to iframeURL if present in the current URL
  let finalIframeURL = isInApp ? `${iframeURL}?in_app=true` : iframeURL;
  finalIframeURL = isLandingPage
    ? isInApp
      ? `${finalIframeURL}&landing_page=true`
      : `${finalIframeURL}?landing_page=true`
    : finalIframeURL;

  // Determine iframe height based on the route
  const iframeHeight = isMobile
    ? path === "/urgent-help" || path === "/panic-help"
      ? "calc(70vh - 132px)" // Smaller height for mobile on panic-help and urgent-help
      : "calc(100vh - 132px)" // Default height for mobile
    : path === "/urgent-help" || path === "/about"
    ? "55vh"
    : path === "/panic-help"
    ? "70vh" // Set 75vh for the panic-help route on desktop
    : "65vh"; // Default

  console.log(iframeHeight);

  return (
    <div>
      {/* Landing Page Design */}
      {isLandingPage ? (
        <>
          <section className="static-section">
            {/* Left Section */}
            <div className="left-static-section">
              {/* Mobile Image Section */}
              {isMobile &&
                (path === "/panic-help" || path === "/urgent-help") && (
                  <div
                    className="mobile-image-container"
                    style={{ textAlign: "center" }}
                  >
                    <img
                      src={path === "/panic-help" ? panicHelp : urgentHelp}
                      alt={
                        path === "/panic-help" ? "panic-help" : "urgent-help"
                      }
                      className={
                        path === "/panic-help"
                          ? "mobile-panic-help-img"
                          : "mobile-urgent-help-img"
                      }
                    />
                  </div>
                )}
              {!isMobile && (
                <img
                  src={appLogoDesktop}
                  alt="BetterNow Logo"
                  className="static-logo"
                />
              )}
              <iframe
                src={finalIframeURL}
                title={title}
                style={{
                  height: iframeHeight,
                  marginLeft: isMobile ? "0px" : "-20px",
                  border: "none",
                  overflowY: "auto",
                }}
              ></iframe>
            </div>

            {/* Right Section */}
            {!isMobile && (
              <div className="right-static-section">
                <img
                  src={
                    path === "/panic-help"
                      ? panicHelp // Display panic-help image for the panic-help route
                      : path === "/urgent-help"
                      ? urgentHelp
                      : betternowUser
                  }
                  alt={
                    path === "/panic-help"
                      ? "panic-help"
                      : path === "/urgent-help"
                      ? "urgent-help"
                      : "betternow-user"
                  }
                  className={
                    path === "/panic-help"
                      ? "panic-help-img" // Add a class for panic-help image if needed
                      : path === "/urgent-help"
                      ? "urgent-help-img"
                      : "device-user"
                  }
                />
              </div>
            )}
          </section>

          {/* Footer for mobile */}
          {params.get("landing_page") === "true" && (
            <footer>
              <a href="/about?landing_page=true" target="_blank">
                About
              </a>
              <a href="mailto:support@betternow.ai">Contact</a>
              <a href="/terms-and-conditions?landing_page=true" target="_blank">
                Terms
              </a>
              <a href="/privacy-policy?landing_page=true" target="_blank">
                Privacy
              </a>
            </footer>
          )}
        </>
      ) : (
        // Existing Design (if not landing page)
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <iframe
            src={finalIframeURL}
            title={title}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              overflowY: "auto",
            }}
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default StaticPage;
